import React from 'react';

export default function Footer() {
  return (
    <div className="copyright">
      &copy; Paradigm Shift. All rights reserved. Design:
      <a href="https://html5up.net">HTML5 UP</a>.
    </div>
      

  );
}


